@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss"
@import "node_modules/@fortawesome/fontawesome-free/scss/regular.scss"
@import "node_modules/@fortawesome/fontawesome-free/scss/solid.scss"
@import "node_modules/@fortawesome/fontawesome-free/scss/brands.scss"

@import "node_modules/bulma/sass/utilities/initial-variables"
$primary: #754200
@import "node_modules/bulma/bulma"

/* LAYOUT STYLES */
body
  font-family: 'Helvetica Neue', Helvetica, Arial, serif
  font-size: 15px
  font-weight: 400
  line-height: 1.5
  color: #666
  //background: #fafafa url(../images/body-bg.jpg) 0 0 repeat

.hero
  padding-top: 40px
  padding-bottom: 40px
  background: #2e7bcf url(../src/images/header-bg.jpg) 0 0 repeat-x
  border-bottom: solid 1px #275da1

.hero,
.main-section h1,
.main-section h2,
.main-section h3
  font-family: 'Architects Daughter', 'Helvetica Neue', Helvetica, Arial, serif

.main-section h1:before
  padding-right: 0.3em
  color: #9ddcff
  content: "/"

.main-section h2:before
  padding-right: 0.3em
  content: "//"
  color: #9ddcff

.main-section h3:before
  padding-right: 0.3em
  content: "///"
  color: #9ddcff

// Slide: https://www.joezimjs.com/javascript/pure-css-slide-down-animation/
.is-open
  max-height: 2000px
  transition: all .3s ease-in

.is-closed
  line-height: 0
  margin: 0 !important
  padding: 0 !important
  color: transparent
  transition: all .3s ease-in

  *
    margin: 0 !important
    padding: 0 !important

.is-collapsed .icon
  transition: all .3s ease-in

.is-expanded .icon
  transition: all .3s ease-in
  transform: rotate(90deg)
